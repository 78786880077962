import React from 'react'
import { Typography } from '@mui/material'
import VerticalBox from '../styles/VerticalBox.styled'

export default function NoResult(): JSX.Element {
  return (
    <VerticalBox sx={{ justifyContent: 'center' }}>
      <Typography variant="h5" component="p" py={3}>
        No Result
      </Typography>
    </VerticalBox>
  )
}
