import React from 'react'
import { TextField, MenuItem, Box, Grid, Typography } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import Helmet from '../components/Helmet'
import ActionButton from '../styles/ActionButton.styled'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState } from '../redux/configureStore'
import { useGetLocationsQuery } from '../redux/api'
import Modal from '../components/Modal'
import { updateSearchParam } from '../redux/searchSlice'

// Schema of the search filter form
const schema = yup.object().shape({
  location: yup.string().required('Location is required'),
  startDate: yup.string().required('Start date is required'),
  endDate: yup.string().required('End date is required'),
  event: yup.string().required('Event is required'),
})

type FormValues = yup.InferType<typeof schema>

export default function Record(): JSX.Element {
  const navigate = useNavigate()
  const { currentStation } = useSelector((state: RootState) => state.station)
  const { data, isError } = useGetLocationsQuery(currentStation)
  const dispatch = useDispatch()
  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema), // use yup to validate form values
  })

  // Form submit handler
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const start = dayjs(data.startDate).format('YYYY-MM-DD')
    const end = dayjs(data.endDate).format('YYYY-MM-DD')
    dispatch(
      updateSearchParam({
        location: data.location,
        start,
        end,
        event: data.event,
      }),
    )
    navigate('/record-result')
  }

  const watchStartDate = watch('startDate')

  // Modal handler
  const handleConfirm = (): void => {
    navigate(0)
  }

  return (
    <>
      <Helmet title="Record" />
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Controller
          name="location"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              select
              fullWidth
              id="location"
              label="Location"
              disabled={data?.length === 0}
              error={!(errors.location === undefined)}
              helperText={errors.location?.message}
              {...field}
            >
              <MenuItem value="all">All</MenuItem>
              {data !== undefined ? (
                data.map((item) => (
                  <MenuItem key={item.locationCode} value={item.locationCode}>
                    {item.locationName.en}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>Loading...</MenuItem>
              )}
            </TextField>
          )}
        />
        <Grid container spacing={1}>
          <Grid item xs={5.5}>
            <Controller
              name="startDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="Start Date"
                    maxDate={dayjs()}
                    format="YYYY-MM-DD"
                    slotProps={{
                      textField: {
                        id: 'start-date',
                        error: !(errors.startDate === undefined),
                        helperText: errors.startDate?.message,
                        fullWidth: true,
                      },
                    }}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>to</Typography>
          </Grid>
          <Grid item xs={5.5}>
            <Controller
              name="endDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    label="End Date"
                    minDate={watchStartDate ?? dayjs()}
                    maxDate={dayjs()}
                    format="YYYY-MM-DD"
                    disabled={
                      watchStartDate === undefined || watchStartDate === ''
                    }
                    slotProps={{
                      textField: {
                        id: 'end-date',
                        error: !(errors.endDate === undefined),
                        helperText: errors.endDate?.message,
                        fullWidth: true,
                      },
                    }}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="event"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              select
              fullWidth
              id="event"
              label="Event"
              error={!(errors.event === undefined)}
              helperText={errors.event?.message}
              {...field}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="fall">Fall Down</MenuItem>
              <MenuItem value="longStay">Long Stay</MenuItem>
            </TextField>
          )}
        />
        <ActionButton
          fullWidth
          color="info"
          type="submit"
          disabled={data === undefined || data?.length === 0}
        >
          Search
        </ActionButton>
        <ActionButton
          fullWidth
          color="secondary"
          type="reset"
          onClick={() => {
            reset()
          }}
          disabled={data === undefined || data?.length === 0}
        >
          Clear
        </ActionButton>
      </Box>
      <Modal
        content={'Get location list failed.'}
        isOpen={isError}
        onClose={handleConfirm}
        confirm={handleConfirm}
        confirmText="Retry"
      />
    </>
  )
}
