import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { searchParamInterface } from '../utils/types'

const initialState = {
  location: '' as string,
  start: '' as string,
  end: '' as string,
  event: '' as string,
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchParam: (state, action: PayloadAction<searchParamInterface>) => {
      state.location = action.payload.location
      state.start = action.payload.start
      state.end = action.payload.end
      state.event = action.payload.event
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateSearchParam } = searchSlice.actions

export default searchSlice.reducer
