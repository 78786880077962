import React from 'react'
import Helmet from '../components/Helmet'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ActionButton from '../styles/ActionButton.styled'

export default function ErrorPage(): JSX.Element {
  const navigate = useNavigate()
  const backHandler = (): void => {
    navigate('/')
  }
  return (
    <>
      <Helmet title="404 - Page Not Found" />
      <Typography component="h2" variant="h4">
        404 - Page Not Found
      </Typography>
      <Typography variant="body1">
        The page you are looking for does not exist.
      </Typography>
      <ActionButton fullWidth color="info" onClick={backHandler} sx={{ my: 2 }}>
        Back to Dashboard
      </ActionButton>
    </>
  )
}
