import React from 'react'
import { styled } from '@mui/system'
import type { Theme } from '@mui/material/styles'
import { Box, Typography, Skeleton, Button } from '@mui/material'
import Helmet from '../components/Helmet'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { useSelector } from 'react-redux'
import type { RootState } from '../redux/configureStore'
import NoResult from '../components/NoResult'
import Modal from '../components/Modal'
import { useGetLocationsQuery, useEditLocationMutation } from '../redux/api'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const MaintenanceBox = styled(Box)(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme?.spacing(2),
  marginBottom: theme?.spacing(2),
  borderRadius: theme?.shape.borderRadius,
  backgroundColor: theme?.palette.background.paper,
  boxShadow: theme?.shadows[1],
})) as typeof Box

function MaintenanceBoard({
  name,
  status,
  loading = false,
  testMode,
  id,
}: {
  name?: string
  status?: string
  loading?: boolean
  testMode?: boolean
  id: string
}): JSX.Element {
  const [editLocation, { isLoading }] = useEditLocationMutation()
  // const [switchValue, setSwitchValue] = useState(testMode);

  const handleButtonClick = async (): Promise<void> => {
    try {
      await editLocation({ id, testMode: !testMode })
      // setSwitchValue(!switchValue);
      toast.success('Changed', { position: toast.POSITION.TOP_CENTER })
      // window.location.reload() // Refresh the page
    } catch (error) {
      toast.error('Error', { position: toast.POSITION.TOP_CENTER })
    }
  }
  return (
    <React.Fragment>
      <MaintenanceBox>
        {loading ? (
          <Skeleton width="70%" />
        ) : (
          <Typography variant="h5">{name}</Typography>
        )}
        {loading ? (
          <Skeleton variant="circular" width="30px" height="30px" />
        ) : status === 'online' ? (
          <CheckCircleRoundedIcon color="success" fontSize="large" />
        ) : (
          <CancelRoundedIcon color="error" fontSize="large" />
        )}
        <Button
          onClick={handleButtonClick}
          disabled={isLoading}
          color={testMode ? 'success' : 'primary'}
        >
          Test
        </Button>
      </MaintenanceBox>
    </React.Fragment>
  )
}

export default function Maintenance(): JSX.Element {
  const { currentStation } = useSelector((state: RootState) => state.station)
  const { data, isLoading, isSuccess, isError } =
    useGetLocationsQuery(currentStation)
  const navigate = useNavigate()

  // Modal handler
  const handleConfirm = (): void => {
    navigate(0)
  }

  return (
    <>
      <Helmet title="Maintenance" />
      {isSuccess &&
        data?.length > 0 &&
        data?.map((item) => (
          <MaintenanceBoard
            key={item.locationCode}
            name={item.locationName.en}
            status={item.health}
            id={item.id}
            testMode={item.testMode}
          />
        ))}
      {isLoading && (
        <>
          <MaintenanceBoard loading id={''} />
          <MaintenanceBoard loading id={''} />
          <MaintenanceBoard loading id={''} />
        </>
      )}
      {isSuccess && data?.length === 0 && <NoResult />}
      {
        <Modal
          content={'Get location list failed.'}
          isOpen={isError}
          onClose={handleConfirm}
          confirm={handleConfirm}
          confirmText="Retry"
        />
      }
    </>
  )
}
