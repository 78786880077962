import React, { useState, useEffect } from 'react'
import {
  TextField,
  MenuItem,
  Box,
  Grid,
  Typography,
  IconButton,
  Skeleton,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useForm, Controller } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ActionButton from '../styles/ActionButton.styled'
import Helmet from '../components/Helmet'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import VerticalBox from '../styles/VerticalBox.styled'
import { useSelector } from 'react-redux'
import type { RootState } from '../redux/configureStore'
import type { LocationItems } from '../utils/types'
import NoResult from '../components/NoResult'
import { useGetLocationsQuery } from '../redux/api'

// Schema of the search filter form
const schema = yup.object().shape({
  location: yup.array().required('Location is required'),
})

type FormValues = yup.InferType<typeof schema>

const Instruction = ({
  text,
  color,
}: {
  text: string
  color: string
}): JSX.Element => (
  <Grid
    item
    xs={4}
    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}
  >
    <Typography sx={{ fontWeight: 'bold' }}>{text}</Typography>
    <Box
      sx={{
        backgroundColor: color,
        width: 34,
        height: '1rem',
        borderRadius: 2,
        ml: 1,
      }}
    />
  </Grid>
)

const LocationBar = ({
  locationName,
  color,
  loading = false,
}: {
  locationName?: string
  color?: string
  loading?: boolean
}): JSX.Element => (
  <Box
    sx={{
      backgroundColor: loading ? 'white' : color,
      borderRadius: 3,
      px: 2,
      py: 1,
      mb: 1,
    }}
  >
    {loading ? (
      <Skeleton width="50%" />
    ) : (
      <Typography color="white" sx={{ fontWeight: 'bold' }}>
        {locationName}
      </Typography>
    )}
  </Box>
)

export default function LocationStatus(): JSX.Element {
  const theme = useTheme()
  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema), // use yup to validate form values
  })

  // Form submit handler
  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    if (data !== undefined) {
      if (formData.location.length === 0) {
        setList(data)
      } else {
        const newList = data.filter((item) =>
          formData.location.includes(item.locationCode),
        )
        setList(newList)
      }
    }
  }

  // List of locations
  const [list, setList] = useState<LocationItems>([])
  const { currentStation } = useSelector((state: RootState) => state.station)
  const { data, isLoading, isSuccess, isError } =
    useGetLocationsQuery(currentStation)

  useEffect(() => {
    if (data !== undefined) {
      setList(data)
    }
  }, [data])

  const value = watch('location')

  // Form Reset handler
  const handleReset = (): void => {
    reset() // Reset the form
    if (data !== undefined) {
      setList(data) // Reset the list to the original list
    }
  }

  return (
    <>
      <Helmet title="Toilet Status" />
      <Controller
        name="location"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mb: 2 }}
          >
            <VerticalBox sx={{ mb: 2 }}>
              <TextField
                id="select"
                label="Location"
                select
                fullWidth
                disabled={data?.length === 0}
                SelectProps={{
                  multiple: true,
                }}
                error={!(errors.location === undefined)}
                helperText={errors.location?.message}
                {...field}
              >
                {data?.map((item) => (
                  <MenuItem key={item.locationCode} value={item.locationCode}>
                    {item.locationName.en}
                  </MenuItem>
                ))}
                {data === undefined && (
                  <MenuItem value="" disabled>
                    Loading...
                  </MenuItem>
                )}
              </TextField>
              {value?.length > 0 && ( // If there is any location selected, show the reset icon button
                <IconButton type="reset" onClick={handleReset}>
                  <CancelRoundedIcon />
                </IconButton>
              )}
            </VerticalBox>
            <ActionButton
              fullWidth
              color="info"
              type="submit"
              disabled={data?.length === 0}
            >
              Search
            </ActionButton>
          </Box>
        )}
      />
      <Grid container spacing={2}>
        <Instruction text="Normal" color={theme.palette.success.main} />
        <Instruction text="Warning" color={theme.palette.warning.main} />
        <Instruction text="Danger" color={theme.palette.error.main} />
      </Grid>
      <Box>
        {isSuccess &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <LocationBar
                key={index}
                locationName={item.locationName.en}
                color={
                  item.state === 'normal'
                    ? theme.palette.success.main
                    : item.state === 'warning'
                      ? theme.palette.warning.main
                      : theme.palette.error.main
                }
              />
            )
          })}
        {isLoading && (
          <>
            <LocationBar loading={true} />
            <LocationBar loading={true} />
            <LocationBar loading={true} />
          </>
        )}
        {((isSuccess && list.length === 0) || isError) && <NoResult />}
      </Box>
    </>
  )
}
