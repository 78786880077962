import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Login from '../layouts/Login'
import Nav from '../layouts/Nav'
import Home from '../pages/Home'
import AlertList from '../pages/AlertList'
import PageTitle from '../layouts/PageTitle'
import ErrorPage from '../pages/ErrorPage'
import LocationStatus from '../pages/LocationStatus'
import Record from '../pages/Record'
import Maintenance from '../pages/Maintenance'
import RecordResult from '../pages/RecordResult'
import SelectStation from '../pages/SelectStation'
// import ProtectedRoutes from '../layouts/ProtectedRoutes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
    children: [
      {
        path: '/',
        element: <Nav />,
        children: [
          {
            path: '/',
            element: <Home />,
          },
          {
            path: 'alert',
            element: (
              <PageTitle isAlert={true} title="Alert Acknowledgement">
                <AlertList />
              </PageTitle>
            ),
          },
          {
            path: 'status',
            element: (
              <PageTitle title="Toilet Status">
                <LocationStatus />
              </PageTitle>
            ),
          },
          {
            path: 'record',
            element: (
              <PageTitle title="Record">
                <Record />
              </PageTitle>
            ),
          },
          {
            path: 'record-result',
            element: (
              <PageTitle title="Record">
                <RecordResult />
              </PageTitle>
            ),
          },
          {
            path: 'maintenance',
            element: (
              <PageTitle title="Health Status">
                <Maintenance />
              </PageTitle>
            ),
          },
          {
            path: 'select-station',
            element: (
              <PageTitle title="Select Station">
                <SelectStation />
              </PageTitle>
            ),
          },
          {
            path: '*',
            element: (
              <PageTitle isAlert={true} title="Error">
                <ErrorPage />
              </PageTitle>
            ),
          },
        ],
      },
    ],
  },
])

export default router
