import React from 'react'
import { Typography, Icon } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { SvgIconComponent } from '@mui/icons-material'
import VerticalBox from '../styles/VerticalBox.styled'

// Component of the Alert Card Description
export default function AlertCardRow({
  icon,
  label,
  text,
  color,
  size = '0.9rem',
  last = false,
}: {
  icon?: SvgIconComponent
  label?: string
  text?: string
  color?: string
  size?: string
  last?: boolean
}): JSX.Element {
  const theme = useTheme()
  let boxColor = theme.palette.secondary.dark
  if (color === 'error') {
    boxColor = theme.palette.error.main
  } else if (color === 'warning') {
    boxColor = theme.palette.warning.main
  } else if (color === 'info') {
    boxColor = theme.palette.info.main
  } else if (color === 'common') {
    boxColor = theme.palette.common.black
  }
  return (
    <VerticalBox sx={{ color: boxColor, mb: last ? 0 : 1 }}>
      {icon != null && (
        <Icon component={icon} sx={{ fontSize: `${size}!important` }} />
      )}
      {label != null && (
        <Typography ml={icon != null ? 1 : 0} fontSize={size} fontWeight="bold">
          {label}
        </Typography>
      )}
      {text != null && (
        <Typography ml={1} fontSize={size}>
          {text}
        </Typography>
      )}
    </VerticalBox>
  )
}
