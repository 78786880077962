import React from 'react'
import { Box, Card, CardActions, CardContent, Skeleton } from '@mui/material'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import ActionButton from '../styles/ActionButton.styled'
import TextDataRow from './TextDataRow'
import { useEditAlertMutation } from '../redux/api'
// import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

// import type { SvgIconTypeMap } from '@mui/material'
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

// Style of the Card
const AlertCardBoard = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
})) as typeof Card

export default function AlertCard({
  id,
  type,
  timestamp,
  location,
  event,
  button = false,
  loading = false,
}: {
  id: string
  type: string
  timestamp: Date
  location: string
  event?: string
  button?: boolean
  loading?: boolean
}): JSX.Element {
  const theme = useTheme()
  const typeName = type.charAt(0).toLocaleUpperCase() + type.slice(1)
  const date = dayjs(timestamp).format('DD/MM/YYYY')
  const time = dayjs(timestamp).format('HH:mm:ss')
  const [editAlerts] = useEditAlertMutation()
  const handleAcknowledge = async (id: string): Promise<void> => {
    try {
      await editAlerts(id).unwrap()
      // toast.success('Acknowledged', { position: toast.POSITION.TOP_CENTER })
      window.location.reload() // Refresh the page
      console.log('Alert trigger deleted successfully')
    } catch (error) {
      console.error('Error deleting alert trigger:', error)
    }
  }

  return (
    <>
      <AlertCardBoard>
        {type === 'fall' && (
          <Box
            sx={{ backgroundColor: theme.palette.error.main, width: '4px' }}
          />
        )}
        {type === 'longStay' && (
          <Box
            sx={{ backgroundColor: theme.palette.warning.main, width: '4px' }}
          />
        )}
        {type !== 'fall' && type !== 'longStay' && (
          <Box
            sx={{ backgroundColor: theme.palette.info.main, width: '4px' }}
          />
        )}
        <CardContent
          sx={{ paddingBottom: `${theme.spacing(2)}!important`, width: '100%' }}
        >
          {loading && (
            <Skeleton width="30%" sx={{ fontSize: '1.2rem', mb: 1 }} />
          )}
          {!loading && type === 'fall' && (
            <TextDataRow
              icon={DangerousRoundedIcon}
              label={typeName}
              color="error"
              size="1.2rem"
            />
          )}
          {!loading && type === 'longStay' && (
            <TextDataRow
              icon={WarningRoundedIcon}
              label={typeName}
              color="warning"
              size="1.2rem"
            />
          )}
          {!loading && type !== 'longStay' && type !== 'fall' && (
            <TextDataRow
              icon={InfoRoundedIcon}
              label={typeName}
              color="info"
              size="1.2rem"
            />
          )}
          {loading ? (
            <>
              <Skeleton width="70%" sx={{ fontSize: '0.9rem', mb: 1 }} />
              <Skeleton width="70%" sx={{ fontSize: '0.9rem', mb: 1 }} />
              <Skeleton width="70%" sx={{ fontSize: '0.9rem', mb: 1 }} />
              <Skeleton width="70%" sx={{ fontSize: '0.9rem', mb: 1 }} />
            </>
          ) : (
            <>
              <TextDataRow icon={LocationOnRoundedIcon} text={location} />
              <TextDataRow icon={CalendarTodayRoundedIcon} text={date} />
              <TextDataRow icon={AccessTimeRoundedIcon} text={time} />
              {event !== undefined && (
                <TextDataRow icon={InfoRoundedIcon} text={event} last={true} />
              )}
            </>
          )}
          {button && (
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 0,
                mt: 1,
                width: '100%',
              }}
            >
              {loading ? (
                <>
                  <Skeleton variant="rectangular" width={133} height={34} />
                  <Skeleton variant="rectangular" width={91} height={34} />
                </>
              ) : (
                <>
                  <ActionButton
                    color="success"
                    onClick={async () => {
                      await handleAcknowledge(id)
                    }}
                  >
                    Acknowledge
                  </ActionButton>
                  <ActionButton color="secondary">Cancel</ActionButton>
                </>
              )}
            </CardActions>
          )}
        </CardContent>
      </AlertCardBoard>
    </>
  )
}
