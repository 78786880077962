import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from '../redux/configureStore'
import { useGetAreasQuery } from '../redux/api'

export default function useStationName(): string {
  const { currentStation } = useSelector((state: RootState) => state.station)

  const { data } = useGetAreasQuery()

  const [stationName, setStationName] = useState('')
  useEffect(() => {
    const station = data?.find((station) => station.areaCode === currentStation)
    if (station !== undefined) {
      setStationName(station.areaName.en)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStation, data])

  return stationName
}
