import Button from '@mui/material/Button'
import type { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/system'

// Button style
const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '12px',
  textTransform: 'none',
  fontWeight: 'bold',
  padding: theme.spacing(0.6, 3),
})) as typeof Button

export default ActionButton
