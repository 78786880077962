import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import type { RootState } from '../redux/configureStore'
import { useGetLogsQuery } from '../redux/api'
import Helmet from '../components/Helmet'
import AlertCard from '../components/AlertCard'
import ActionButton from '../styles/ActionButton.styled'
import NoResult from '../components/NoResult'
import Modal from '../components/Modal'
import { Pagination, Box } from '@mui/material'

export default function RecordResult(): JSX.Element {
  const navigate = useNavigate()
  const { location, start, end, event } = useSelector(
    (state: RootState) => state.search,
  )
  const [page, setPage] = useState(1)
  const { data, isSuccess, isLoading, isError } = useGetLogsQuery({
    location,
    start,
    end,
    event,
    page,
  })
  const current = dayjs(dayjs().format('YYYY-MM-DD'))
  const startDay = dayjs(start)
  const endDay = dayjs(end)

  const [noData, setNoData] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangePage = (event: any, value: number): void => {
    setPage(value)
  }
  useEffect(() => {
    // If any of the search params is null, redirect to record page
    if (location == null || start == null || end == null || event == null) {
      setNoData(true)
    }

    const validStartDay = startDay.isValid()
    const validEndDay = endDay.isValid()
    const currentStart = current.diff(startDay)
    const currentEnd = current.diff(endDay)
    const compareDay = endDay.diff(startDay)

    // If any of the date params is invalid, redirect to record page
    if (
      !validStartDay ||
      !validEndDay ||
      currentStart < 0 ||
      currentEnd < 0 ||
      compareDay < 0
    ) {
      setNoData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Search again handler
  const handleSearchAgain = (): void => {
    navigate('/record')
  }
  // Modal handler
  const handleConfirm = (): void => {
    navigate(0)
  }
  return (
    <>
      <Helmet title="Record" />
      <ActionButton
        fullWidth
        color="info"
        sx={{ mb: 2 }}
        onClick={handleSearchAgain}
      >
        Search Again
      </ActionButton>
      {!noData &&
        data?.items?.map((log) => (
          <AlertCard
            key={log.id}
            id={log.id}
            type={log.operation}
            timestamp={new Date(Date.parse(log.timestamp))}
            location={log.locationName.en}
            event={log.event}
            button={false} // No button for record result( default false)
          />
        ))}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={data?.totalPages}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      {noData && <NoResult />}
      {isLoading && <p>Loading...</p>}
      {
        <Modal
          content={'Get Log list failed.'}
          isOpen={isError}
          onClose={handleConfirm}
          confirm={handleConfirm}
          confirmText="Retry"
        />
      }
      {isSuccess && data.items?.length === 0 && <NoResult />}
    </>
  )
}
