import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import ActionButton from '../styles/ActionButton.styled'

export default function Modal({
  content,
  isOpen,
  onClose,
  confirm,
  confirmText = 'Confirm',
}: {
  content: string | undefined
  isOpen: boolean
  onClose: () => void
  confirm: () => void
  confirmText?: string
}): JSX.Element {
  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton onClick={onClose} color="secondary">
          Cancel
        </ActionButton>
        <ActionButton onClick={confirm} color="info">
          {confirmText}
        </ActionButton>
      </DialogActions>
    </Dialog>
  )
}
