import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import VerticalBox from '../styles/VerticalBox.styled'

export default function PageTitle({
  children,
  isAlert = false,
  title,
}: {
  children: React.ReactNode
  isAlert?: boolean
  title: string
}): JSX.Element {
  const theme = useTheme()
  return (
    <>
      <VerticalBox
        sx={{
          gap: '1rem',
          backgroundColor: isAlert
            ? theme.palette.error.light
            : theme.palette.secondary.light,
        }}
        px={3}
        py={1}
        className="page-title"
      >
        {isAlert && (
          <WarningRoundedIcon
            sx={{ color: theme.palette.error.dark, fontSize: '1.8rem' }}
          />
        )}
        <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
      </VerticalBox>
      <Box mx={3} mt={3} component="main">
        {children}
      </Box>
    </>
  )
}
