import React, { useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import { useSelector } from 'react-redux'
import type { RootState } from '../redux/configureStore'
import useStationName from '../hooks/useStationName'

const ContainerBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}))

export default function Nav(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentStation, username, displayName, email } = useSelector(
    (state: RootState) => state.station,
  )
  const stationName = useStationName()

  const handleBack = (): void => {
    if (currentStation === '') {
      navigate('/select-station')
    } else {
      navigate('/')
    }
  }

  const urlParams = new URLSearchParams({
    username,
    station: currentStation,
    displayName,
    email,
  })

  useEffect(() => {
    if (currentStation !== '') {
      navigate({
        pathname: location.pathname,
        search: urlParams.toString(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStation, location.pathname])

  return (
    // Layout with navbar
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {location.pathname === '/' ||
              location.pathname === '/select-station' ||
              currentStation === ''
                ? 'Alert Detection System'
                : stationName}
            </Typography>
            {location.pathname !== '/' &&
              location.pathname !== '/select-station' && (
                <Button
                  variant="text"
                  sx={{ color: 'white' }}
                  onClick={handleBack}
                >
                  Back
                </Button>
              )}
            {/* {
              (location.pathname === '/') &&
              <Button variant="text" sx={{ color: 'white' }} onClick={() => { navigate('/select-station') }}>Back</Button>
            } */}
          </Toolbar>
        </AppBar>
      </Box>
      <ContainerBox>
        <Outlet />
      </ContainerBox>
    </>
  )
}
