import React from 'react'
import Helmet from '../components/Helmet'
import { Box, MenuItem, TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import type { AppDispatch } from '../redux/configureStore'
import { updateCurrentStation } from '../redux/stationSlice'
import { useGetAreasQuery } from '../redux/api'
import Modal from '../components/Modal'
import VerticalBox from '../styles/VerticalBox.styled'
import ActionButton from '../styles/ActionButton.styled'

// Schema of the search filter form
const schema = yup.object().shape({
  station: yup.string().required('Station is required'),
})

type FormValues = yup.InferType<typeof schema>

export default function SelectStation(): JSX.Element {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const { data, isError } = useGetAreasQuery()

  // React hook form setup
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema), // use yup to validate form values
  })

  // Form submit handler
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    dispatch(updateCurrentStation(data.station))
    navigate('/')
  }

  // Modal handler
  const handleConfirm = (): void => {
    navigate(0)
  }

  return (
    <>
      <Helmet title="Select Station" />
      <Controller
        name="station"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ mb: 2 }}
          >
            <VerticalBox sx={{ mb: 2 }}>
              <TextField
                id="station"
                label="Station"
                select
                fullWidth
                error={!(errors.station === undefined)}
                helperText={errors.station?.message}
                disabled={data === undefined}
                {...field}
              >
                {data !== undefined ? (
                  data?.map((item) => (
                    <MenuItem key={item.areaCode} value={item.areaCode}>
                      {item.areaName.en}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    Loading...
                  </MenuItem>
                )}
              </TextField>
            </VerticalBox>
            <ActionButton
              fullWidth
              color="info"
              type="submit"
              disabled={data === undefined}
            >
              Search
            </ActionButton>
          </Box>
        )}
      />
      {
        <Modal
          content="Get station list failed."
          isOpen={isError}
          onClose={handleConfirm}
          confirm={handleConfirm}
          confirmText="Retry"
        />
      }
    </>
  )
}
