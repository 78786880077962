import React, { useState } from 'react'
import AlertCard from '../components/AlertCard'
import Helmet from '../components/Helmet'
import NoResult from '../components/NoResult'
import Modal from '../components/Modal'
// import type { RootState } from '../redux/configureStore'
// import { useSelector } from 'react-redux'
import { useGetAlertsQuery } from '../redux/api'
import { useNavigate } from 'react-router'
import { Pagination, Box } from '@mui/material'

export default function AlertList(): JSX.Element {
  // Modal handler
  const navigate = useNavigate()
  const handleConfirm = (): void => {
    navigate(0)
  }
  // const { currentStation } = useSelector((state: RootState) => state.station)
  const [page, setPage] = useState(1)
  const { data, isSuccess, isLoading, isError } = useGetAlertsQuery({ page })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangePage = (event: any, value: number): void => {
    setPage(value)
  }
  return (
    <>
      <Helmet title="Alert List" />
      {isSuccess &&
        data.items?.length > 0 &&
        data.items?.map((alert) => {
          if (alert.action === 'pending') {
            // Add condition to check action value
            return (
              <AlertCard
                key={alert.id}
                id={alert.id}
                type={alert.state}
                timestamp={new Date(Date.parse(alert.timestamp))}
                location={alert.locationName.en}
                button={true}
              />
            )
          }
          return null // Return null if action is 'acknowledged'
        })}
      {isSuccess && data.items?.length === 0 && <NoResult />}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={data?.totalPages}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      {isLoading && <p>Loading...</p>}
      {
        <Modal
          content={'Get Alert list failed.'}
          isOpen={isError}
          onClose={handleConfirm}
          confirm={handleConfirm}
          confirmText="Retry"
        />
      }
    </>
  )
}
