import React, { useEffect, useState } from 'react'
import { usePostAuthMutation } from '../redux/auth'
import {
  CircularProgress,
  Typography,
  AppBar,
  Box,
  Toolbar,
} from '@mui/material'
import { Outlet } from 'react-router-dom'
import VerticalBox from '../styles/VerticalBox.styled'
import { useDispatch } from 'react-redux'
import type { AppDispatch } from '../redux/configureStore'
import { updateUserData } from '../redux/stationSlice'
import type { userLoginInterface } from '../utils/types'

export default function Login(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>()

  // usePostAuthMutation to login and get cookie from server
  const [postAuth, { isLoading, isSuccess, isError }] = usePostAuthMutation()
  const [noStation, setNoStation] = useState(false)

  const urlParams = new URLSearchParams(location.search)
  const station = urlParams.get('station')
  const username = urlParams.get('username')
  const displayName = urlParams.get('displayName')
  const email = urlParams.get('email')
  const login = async (user: userLoginInterface): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    await postAuth({
      organizationCode: 'MTR',
      areaCode: user.station,
      userData: {
        username,
        displayName,
        email,
      },
    }).unwrap()
    dispatch(updateUserData(user))
  }

  useEffect(() => {
    if (
      station != null &&
      username != null &&
      displayName != null &&
      email != null
    ) {
      void login({ username, station, displayName, email })
    } else {
      setNoStation(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {isLoading && (
        <VerticalBox>
          <CircularProgress />
        </VerticalBox>
      )}
      {isSuccess && <Outlet />}
      {(isError || noStation) && (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Alert Detection System
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <VerticalBox p={2}>
            <Typography variant="body1">
              Error, please contact the administrator.
            </Typography>
          </VerticalBox>
        </>
      )}
    </div>
  )
}
