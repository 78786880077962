import React from 'react'
import { Box, Icon } from '@mui/material'
import type { SvgIconComponent } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import Helmet from '../components/Helmet'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import WcRoundedIcon from '@mui/icons-material/WcRounded'
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded'
import BuildRoundedIcon from '@mui/icons-material/BuildRounded'
import LargeButton from '../styles/LargeButton.styled'
import TextDataRow from '../components/TextDataRow'
import useStationName from '../hooks/useStationName'
import { useGetAlertsQuery } from '../redux/api'

function HomeButton({
  text,
  icon,
  path,
  alert = false,
}: {
  text: string
  icon: SvgIconComponent
  path: string
  alert?: boolean
}): JSX.Element {
  const navigate = useNavigate()
  const handleClick = (): void => {
    navigate(path)
  }
  return (
    <LargeButton
      fullWidth
      size="large"
      startIcon={<Icon component={icon} sx={{ fontSize: '50px!important' }} />}
      onClick={handleClick}
      color={alert ? 'error' : 'secondary'}
    >
      {text}
    </LargeButton>
  )
}

export default function Home(): JSX.Element {
  const stationName = useStationName()
  const { data } = useGetAlertsQuery({ page: 1 })
  const isTotalGreaterThanZero = (data?.total ?? 0) > 0

  return (
    <>
      <Helmet title="Dashboard" />
      <Box m={3}>
        <TextDataRow
          label="Current station:"
          text={stationName}
          size="1rem"
          color="common"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <HomeButton
            text="Alert Acknowledgement"
            icon={WarningRoundedIcon}
            path="/alert"
            alert={isTotalGreaterThanZero}
          />
          <HomeButton
            text="Toilet Status"
            icon={WcRoundedIcon}
            path="/status"
          />
          <HomeButton text="Record" icon={HistoryRoundedIcon} path="/record" />
          <HomeButton
            text="Health Status"
            icon={BuildRoundedIcon}
            path="/maintenance"
          />
        </Box>
      </Box>
    </>
  )
}
