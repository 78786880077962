import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import logger from 'redux-logger'

import stationSlice from './stationSlice'
import searchSlice from './searchSlice'
import { api } from './api'
import { auth } from './auth'

const reducers = combineReducers({
  station: stationSlice,
  search: searchSlice,
  [api.reducerPath]: api.reducer,
  [auth.reducerPath]: auth.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  whitelist: ['station', 'search'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const serializableCheck = {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
    const middleware = getDefaultMiddleware({ serializableCheck })
      .concat(api.middleware)
      .concat(auth.middleware)
    if (process.env.NODE_ENV !== 'production') {
      return middleware.concat(logger)
    }
    return middleware
  },
  devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
