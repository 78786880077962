import React from 'react'
import { Helmet } from 'react-helmet-async'

const SITE_TITLE = 'Abnormal Handling System'
const SITE_DESCRIPTION = 'Abnormal Handling System'

export default function HelmetComponent({
  title = SITE_TITLE,
  description = SITE_DESCRIPTION,
}: {
  title?: string
  description?: string
}): JSX.Element {
  let pageTitle = SITE_TITLE
  if (title !== null) {
    pageTitle = `${title} | ${SITE_TITLE}`
  }
  let pageDesc = SITE_DESCRIPTION
  if (description !== null) {
    pageDesc = description
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDesc} />
      </Helmet>
    </>
  )
}
