import { Box } from '@mui/material'
import type { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/system'

// Box style
const VerticalBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
})) as typeof Box

export default VerticalBox
