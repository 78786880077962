import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { userLoginInterface } from '../utils/types'

const initialState = {
  currentStation: '' as string,
  username: '' as string,
  displayName: '' as string,
  email: '' as string,
}

export const stationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    updateCurrentStation: (state, action: PayloadAction<string>) => {
      state.currentStation = action.payload
    },
    updateUserData: (state, action: PayloadAction<userLoginInterface>) => {
      state.currentStation = action.payload.station
      state.username = action.payload.username
      state.displayName = action.payload.displayName
      state.email = action.payload.email
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateCurrentStation, updateUserData } = stationSlice.actions

export default stationSlice.reducer
