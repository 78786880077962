import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  AreaItems,
  LocationItems,
  AlertData,
  LogData,
} from '../utils/types'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  endpoints: (build) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getAreas: build.query<AreaItems, void>({
      query: () => {
        return {
          url: '/areas',
        }
      },
      keepUnusedDataFor: 60 * 60 * 24,
    }),
    getLocations: build.query<LocationItems, string>({
      query: (area) => {
        return {
          url: '/locations',
          params: { area },
        }
      },
    }),
    editLocation: build.mutation<
      Promise<void>,
      { id: string; testMode: boolean }
    >({
      query: ({ id, testMode }) => ({
        url: `/locations/${id}`,
        method: 'PATCH',
        body: { testMode },
      }),
    }),
    getAlerts: build.query<AlertData, { page: number }>({
      query: ({ page }) => ({ url: '/alerts', params: { page } }),
    }),
    editAlert: build.mutation({
      query: (id) => ({
        url: '/alerts',
        method: 'PATCH',
        body: [{ id, action: 'acknowledged', comment: 'acknowledged' }],
      }),
    }),
    getLogs: build.query<
      LogData,
      {
        location: string
        start: string
        end: string
        event: string
        page: number
      }
    >({
      query: ({ location, start, end, event, page }) => ({
        url: '/logs',
        params: { location, start, end, event, page },
      }),
    }),
  }),
})

export const {
  useGetAreasQuery,
  useGetLocationsQuery,
  useEditLocationMutation,
  useGetAlertsQuery,
  useEditAlertMutation,
  useGetLogsQuery,
} = api
