import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { AuthInterface } from '../utils/types'

export const auth = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
  }),
  reducerPath: 'auth',
  endpoints: (build) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    postAuth: build.mutation<void, AuthInterface>({
      query: (credentials) => ({
        url: '/auth/ip-login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
})

export const { usePostAuthMutation } = auth
