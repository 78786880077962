import { createTheme } from '@mui/material'

export const colors = {
  black: '#282828',
  white: '#FFFFFF',
  primary: '#00234F',
  secondary: '#8E8E8E',
  lightText: '#FFFFFF',
  lightBackground: '#F8F8F8',
  error: '#E84032',
  lightError: '#FFB0B0',
  darkError: '#9E0000',
  warning: '#E3AB20',
  success: '#16A661',
  info: '#4790AF',
  disabled: '#EEEEEE',
  grey: '#B9B9B9',
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.lightText,
    },
    secondary: {
      main: colors.secondary,
      light: colors.grey,
      contrastText: colors.lightText,
    },
    success: {
      main: colors.success,
      contrastText: colors.lightText,
    },
    info: {
      main: colors.info,
      contrastText: colors.lightText,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.lightText,
    },
    error: {
      main: colors.error,
      dark: colors.darkError,
      light: colors.lightError,
      contrastText: colors.lightText,
    },
    background: {
      paper: colors.white,
      default: colors.lightBackground,
    },
    common: {
      black: colors.black,
    },
  },
  typography: {
    h3: {
      fontSize: '1.75rem',
      fontWeight: '700',
      color: colors.black,
      marginBottom: '1rem',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '700',
      color: colors.black,
      marginBottom: '1rem',
    },
    h5: {
      fontSize: '1.25rem',
      color: colors.black,
    },
    fontFamily: '"Roboto", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: '0px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor:
            ownerState.disabled === true ? colors.disabled : colors.white,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor:
            ownerState.disabled === true ? colors.disabled : colors.white,
        }),
      },
    },
  },
})

export default theme
